import React from "react";
import Select from "react-select";
import { FiExternalLink } from "react-icons/fi";
import { roundPrice } from "../../../utils/roundPrice";

const Step1 = (props) => {
  const serverOptions = [
    { value: 0, label: "Aucun" },
    {
      value: 5,
      label: `Jusqu'à <span style="color: #e65300">5</span> utilisateurs`,
    },
    {
      value: 10,
      label: `Jusqu'à <span style="color: #e65300">10</span> utilisateurs`,
    },
    {
      value: 25,
      label: `Jusqu'à <span style="color: #e65300">25</span> utilisateurs`,
    },
  ];
  const calendarServicesOptions = [
    { value: "none", label: "Aucun" },
    {
      value: "captainvet",
      label: "CaptainVet",
    },
    {
      value: "doctorManager",
      label: "DoctorManager",
    },
  ];
  const migrationOptions = [
    { value: "none", label: "Aucune" },
    { value: "vetesys", label: "Vetesys" },
    { value: "epivet", label: "Epivet" },
    { value: "fuga", label: "Fuga" },
    { value: "cinnaber", label: "Cinnaber" },
    { value: "vikee", label: "Vikee" },
    { value: "other", label: "Autre" },
  ];

  const handleMigrationChange = (item) => {
    props.formik.setFieldValue("appToMigrate", item.value);
    props.setSelectedMigrationOption(item);
    props.priceFormik.setFieldValue("data_migration", item.value);
    props.priceFormik.submitForm();
    console.log(props.formik.values.appToMigrate);
  };
  const handleServerChange = (item) => {
    props.setSelectedMultiUser(item);
    props.formik.setFieldValue("serverUser", item.value);
    props.priceFormik.setFieldValue("multi_user", item.value);
    props.priceFormik.submitForm();
  };
  const handleCalendarServiceChange = (item) => {
    props.formik.setFieldValue("calendarService", item.value);
    props.setSelectedCalendarOption(item);

    props.priceFormik.setFieldValue("calendar_service", item.value);
    props.priceFormik.submitForm();
  };

  const handleIsYoungVetChange = (item) => {
    props.priceFormik.setFieldValue("is_young_vet", item.target.checked);
    props.formik.setFieldValue("is_young_vet", item.target.checked);
    props.priceFormik.submitForm();
  };
  const colourStyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "-4px",
      marginLeft: "1em",
      color: "#002E6D",
      fontWeight: "500",
      borderColor: state.isFocused
        ? "white"
        : state.isSelected
        ? "white"
        : state.isActived
        ? "white"
        : "#002E6D",
      backgroundColor: "white",
      borderRadius: "7px",
      maxWidth: "220px",
      width: "220px",
      boxShadow: state.isFocused
        ? " 0px 7px 15px rgba(20, 43, 50, 0.1)"
        : "none",
      "&:hover": {
        boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
        borderColor: "white",
      },
      zIndex: "21",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: "500",
        zIndex: "20",
        padding: "0.5em 0.5em 0.5em 1.5em",
        backgroundColor: isDisabled
          ? "red"
          : isFocused
          ? "#FFEBD9"
          : isSelected
          ? "white"
          : "white",
        color: "#002E6D",
        cursor: isDisabled ? "not-allowed" : "default",
        borderRadius: "7px",
        "&:hover": {
          backgroundColor: "#FFEBD9",
        },
        "&:before": {
          content: isSelected ? '"✓"' : '""',
          marginRight: isSelected ? "10px" : "0",
          color: "#E65300",
          position: "absolute",
          left: "0.5em",
        },
      };
    },

    menuList: (provided, state) => ({
      padding: "0.25em",
      boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
      borderColor: "red",
      width: "220px",
      zIndex: "20",
    }),
    menu: (provided, state) => ({
      ...provided,
      paddingTop: "3em",
      marginTop: "-3em",
      boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
      width: "220px",
      zIndex: "20",

      borderColor: state.isFocused
        ? "white"
        : state.isActive
        ? "white"
        : "white",
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      borderColor: "white",
      zIndex: "21",
    }),
    indicatorSeparator: (provided, state) => ({
      display: "hidden",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#E65300",
      "&:hover": {
        color: "#E65300",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#002E6D",
    }),
  };
  const colourStyles2 = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "-4px",
      marginLeft: "1em",
      color: "#002E6D",
      fontWeight: "500",
      borderColor: state.isFocused
        ? "white"
        : state.isSelected
        ? "white"
        : state.isActived
        ? "white"
        : "#002E6D",
      backgroundColor: "white",
      borderRadius: "7px",
      maxWidth: "220px",
      width: "220px",
      boxShadow: state.isFocused
        ? " 0px 7px 15px rgba(20, 43, 50, 0.1)"
        : "none",
      "&:hover": {
        boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
        borderColor: "white",
      },
      zIndex: "19",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: "500",
        padding: "0.5em 0.5em 0.5em 1.5em",
        backgroundColor: isDisabled
          ? "red"
          : isFocused
          ? "#FFEBD9"
          : isSelected
          ? "white"
          : "white",
        color: "#002E6D",
        cursor: isDisabled ? "not-allowed" : "default",
        borderRadius: "7px",
        "&:hover": {
          backgroundColor: "#FFEBD9",
        },
        "&:before": {
          content: isSelected ? '"✓"' : '""',
          marginRight: isSelected ? "10px" : "0",
          color: "#E65300",
          position: "absolute",
          left: "0.5em",
        },
      };
    },

    menuList: (provided, state) => ({
      padding: "0.25em",
      boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
      borderColor: "red",
      width: "220px",
    }),
    menu: (provided, state) => ({
      ...provided,
      paddingTop: "3em",
      marginTop: "-3em",
      boxShadow: " 0px 7px 15px rgba(20, 43, 50, 0.1)",
      width: "220px",

      borderColor: state.isFocused
        ? "white"
        : state.isActive
        ? "white"
        : "white",
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      borderColor: "white",
    }),
    indicatorSeparator: (provided, state) => ({
      display: "hidden",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#E65300",
      "&:hover": {
        color: "#E65300",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#002E6D",
    }),
  };
  return (
    <div>
      <h2 className="text-midnight-blue-500 font-semibold text-3xl mb-3">
        Je choisis ma souscription
      </h2>
      <div className="border border-midnight-blue-500 sm:mr-4 rounded-3xl p-6 text-sm leading-relaxed relative">
        <div className="flex items-center mb-5">
          <div className="flex-auto text-xl font-semibold">
            <h3>Souscription annuelle</h3>
          </div>
          <div className="text-right text-xl sm:text-2xl">
            {/* {new Date().getTime() <= new Date(date50percent).getTime()
              ? "595,00 € HTVA"
              : "297,5 € HTVA"} */}
            {/* {getBasePrice().toFixed(2).replace(".", ",")} */}
            <>
              {props.pricesInfos && (
                <>{props.pricesInfos.subscription_price} € HTVA</>
              )}
            </>
             
          </div>
        </div>
        <div>
          <div>
            <ul className="list-disc ml-6 mb-4 space-y-1">
              <li>
                1 licence d’utilisation du logiciel Pégase par n° de dépôt de
                médicaments, quelque soit le nombre de vétérinaires ou
                d’ordinateurs
                <br />
                <button
                  className="cursor-pointer"
                  onClick={() => props.setIsLicenseOpen(true)}
                >
                  <span className="font-semibold ">Consultez ici</span> la
                  Licence et les conditions d’utilisation du logiciel Pégase
                </button>
              </li>
              <li>toutes les mises à jour du logiciel</li>
              <li>support du logiciel Pégase (télephone, mail, forum, ...)</li>
              <li>
                utilisation de tous les services associés (accès au forum,
                formations, réductions obtenues, ...)
              </li>
              <li>
                2 demi-journées de formation sont offertes pour tout nouvel
                utilisateur
              </li>
            </ul>

            <span
              className={`${
                props.formik.values.is_young_vet ? "line-through" : ""
              }`}
            >
              {" "}
              Un engagement initial de 3 ans minimum est demandé.
            </span>
            <div className="bg-blueish-300 rounded-2xl p-2 mt-4">
              <h4 className="font-semibold ml-2 mb-2">
                Avantages jeune vétérinaire{" "}
                <span className="font-normal">(diplômé ≤ 5 ans)</span>
              </h4>
              <ul className="list-disc ml-8 mb-4 space-y-1">
                <li>-50% la première année, -25% la deuxième année</li>
                <li>Pas d'engagement minimum de 3 ans</li>
              </ul>
              <p className="ml-2">
                {" "}
                <input
                  type="checkbox"
                  id="is_young_vet"
                  name="is_young_vet"
                  className="mr-2 ml-2"
                  checked={props.priceFormik.values.is_young_vet}
                  onChange={handleIsYoungVetChange}
                />
                <label htmlFor="is_young_vet">
                  Je suis un jeune vétérinaire (diplômé ≤ 5 ans).
                </label>
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2 className="text-midnight-blue-500 font-semibold text-3xl mb-3 mt-8 ">
        Options
      </h2>
      <div className="border  border-midnight-blue-500 sm:mr-4 rounded-3xl text-sm py-4 px-3 mb-8 relative">
        {/* {props.isLoadingPrices && (
          <div className="absolute rounded-3xl opacity-95 bg-white top-0 left-0 right-0 bottom-0 z-50 cursor-not-allowed">
            <div className="opacity-100 flex justify-center items-center h-full">
              <Oval
                height={80}
                width={80}
                color="#002E6D"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#549CFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          </div>
        )} */}
        <div className="flex affiliation-dropdown-height  sm:items-center mb-8">
          <div className="w-10/12  flex flex-col sm:flex-row ">
            <div className="text-xl font-semibold">Version serveur</div>
            <div className="flex-1 self-start mt-3 sm:mt-0">
              {/* <ServerDropdown formik={props.formik} /> */}
              <Select
                options={serverOptions}
                styles={colourStyles}
                isSearchable={false}
                formatOptionLabel={function (data) {
                  return (
                    <span dangerouslySetInnerHTML={{ __html: data.label }} />
                  );
                }}
                onChange={handleServerChange}
                placeholder={"Choisir une option"}
                defaultValue={props.selectedMultiUser}
              />
            </div>
          </div>

          <div className="w-2/12 text-right text-xl sm:text-2xl">
            {props.pricesInfos && props.pricesInfos.multi_user.price} €
          </div>
        </div>
        <ul className="list-disc ml-6 space-y-1 mb-8">
          <li>payable une fois à l'installation</li>
          <li>adapté à une installation fixe</li>
        </ul>
      </div>

      <div className="border border-midnight-blue-500 sm:mr-4 rounded-3xl py-4 px-3 mb-8 text-sm relative">
        {/* {props.isLoadingPrices && (
          <div className="absolute rounded-3xl opacity-95 bg-white top-0 left-0 right-0 bottom-0 z-50 cursor-not-allowed">
            <div className="opacity-100 flex justify-center items-center h-full">
              <Oval
                height={80}
                width={80}
                color="#002E6D"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#549CFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          </div>
        )} */}
        <div className="flex affiliation-dropdown-height sm:items-center mb-8">
          <div className=" w-9/12 flex flex-col sm:flex-row">
            <div className=" text-xl font-semibold">Reprises de données</div>
            <div className="flex-1 self-start mt-3 sm:mt-0">
              <Select
                options={migrationOptions}
                styles={colourStyles}
                isSearchable={false}
                onChange={handleMigrationChange}
                placeholder={"Aucune"}
                defaultValue={props.selectedMigrationOption}
              />
            </div>
          </div>
          <div className="w-3/12 text-right text-xl sm:text-2xl">
            {props.formik.values.appToMigrate === "other" ? (
              <>Sur devis</>
            ) : (
              <>
                {" "}
                {props.pricesInfos && props.pricesInfos.data_migration.price} €
              </>
            )}
          </div>
        </div>
        <ul className="list-disc ml-6 space-y-1 mb-8">
          <li>
            Coût forfaitaire pour les logiciels suivants : VETESYS, EPIVET,
            FUGA, VIKEE et CINNABER. Pour tout autre logiciel, demander un
            devis.
          </li>
          <li>
            La comptabilité n'est pas reprise (factures, notes de crédit,
            paiements, ...)
          </li>
        </ul>
      </div>
      <div className="border  border-midnight-blue-500 sm:mr-4 rounded-3xl text-sm py-4 px-3 mb-8 lg:mb-24 relative">
        {/* {props.isLoadingPrices && (
          <div className="absolute rounded-3xl opacity-95 bg-white top-0 left-0 right-0 bottom-0 z-50 cursor-not-allowed">
            <div className="opacity-100 flex justify-center items-center h-full">
              <Oval
                height={80}
                width={80}
                color="#002E6D"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#549CFF"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          </div>
        )} */}
        <div className="flex affiliation-dropdown-height mb-8">
          <div className="w-10/12  flex flex-col sm:flex-row ">
            <div className="text-xl font-semibold">Rendez-vous en ligne</div>
            <div className="flex-1 self-start mt-3 sm:mt-0">
              <Select
                options={calendarServicesOptions}
                styles={colourStyles2}
                isSearchable={false}
                onChange={handleCalendarServiceChange}
                placeholder={"Aucun"}
                defaultValue={props.selectedCalendarOption}
              />
            </div>
          </div>

          <div className="w-2/12 text-right text-xl sm:text-2xl">
            {props.formik.values.calendarService === "none" && <span>0 €</span>}
            {props.formik.values.calendarService !== "none" && (
              <span className="flex flex-col ">
                <span>
                  {" "}
                  {props.pricesInfos &&
                    props.pricesInfos.calendar_service.price}{" "}
                  €
                </span>
                <span className="text-sm ">
                  ({roundPrice(props.pricesInfos.calendar_service.price / 12)}
                  €/mois)
                </span>
              </span>
            )}
          </div>
        </div>
        {props.formik.values.calendarService === "none" && (
          <>
            {" "}
            <ul className="list-disc ml-6 space-y-1 mb-4">
              <li>Solution de prise de rendez-vous par internet</li>
            </ul>
          </>
        )}
        {props.formik.values.calendarService === "doctorManager" && (
          <>
            {" "}
            <ul className="list-disc ml-6 space-y-1 mb-4">
              <li>Solution de prise de rendez-vous par internet</li>
              <li>Avantages partenariat :</li>
              <ul
                className="ml-6 space-y-1"
                style={{ listStyleType: "circle" }}
              >
                <li>Agenda électronique</li>
                <li>
                  Personnalisation de la page de rendez-vous (logo, couleurs,
                  ...)
                </li>
                <li>
                  1800 SMS par an (SMS additionnels à 0,07 euros HT par packs de
                  1000 SMS)
                </li>
              </ul>
              <li>Société belge (Sylho). Vous souscrivez avec cette société</li>
              <li>
                <button
                  onClick={() => props.setIsDoctorManagerCGUOpen(true)}
                  className="cursor-pointer inline"
                >
                  <span className="font-semibold">Consultez ici</span> les
                  conditions d'utilisation de DoctorManager
                </button>
              </li>
            </ul>
            <a
              href="https://doctormanager.be/partenariat-logiciel-veterinaire-pegase"
              className="ml-6 inline-flex items-center gap-x-1  border-b border-midnight-blue-500"
              target={"_blank"}
              rel="noreferrer"
            >
              Plus d'info...{" "}
              <span>
                <FiExternalLink />
              </span>{" "}
            </a>
          </>
        )}
        {props.formik.values.calendarService === "captainvet" && (
          <>
            {" "}
            <ul className="list-disc ml-6 space-y-1 mb-4">
              <li>
                Solution de prise de rendez-vous par internet et agenda
                électronique
              </li>
              {/* <li>Fonction de prépaiement</li> */}
              <li>Avantages partenariat :</li>
              <ul
                className="ml-6 space-y-1"
                style={{ listStyleType: "circle" }}
              >
                <li>Réduction 10% sur le prix officiel</li>
                <li>Intégration avec votre agenda Google</li>
                <li>E-mail de confirmation de rendez-vous + sms de rappel</li>
                <li>Intégration possible sur votre page internet</li>
              </ul>
              <li>Vous souscrivez avec la société CaptainVet</li>

              <li>
                <button
                  onClick={() => props.setIsCaptainVetCGVOpen(true)}
                  className="cursor-pointer inline"
                >
                  <span className="font-semibold">Consultez ici</span> les
                  conditions générales de vente de CaptainVet
                </button>
              </li>
            </ul>
            <a
              href="https://www.cloud.byvets.be/s/qWEAxQLFiqNGDJw"
              className="ml-6 inline-flex items-center gap-x-1  border-b border-midnight-blue-500"
              target={"_blank"}
              rel="noreferrer"
            >
              Plus d'info...{" "}
              <span>
                <FiExternalLink />
              </span>{" "}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default Step1;
